// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views
// mis views
import { Sandbox } from "views/Sandbox/Sandbox.jsx";
import { LoginManager } from "../utils/LoginManager";
import { PatientsContainer } from "./../views/Patients/PatientsContainer";
import { UsersView } from "./../views/Users/UsersView";
import { MedicalRecords } from "../views/Patients/MedicalRecords";

export class DashboardRoutes {
  get = () => {
    const login = new LoginManager();
    let profile = login.getToken();

    if (!profile) {
      return [];
    }

    profile = profile.permissions;
    let profileRender = this.routes.filter(item => {
      let accessCounter = false;
      if (typeof item.permissions === "undefined") {
        return true;
      }

      for (let index in item.permissions) {
        accessCounter = login.hasPermission(item.permissions[index]);
      }
      return accessCounter;
    });

    return profileRender;
  };

  routes = [
    {
      redirect: true,
      path: "/",
      to: "/dashboard",
      navbarName: "Redirect"
    },
    {
      path: "/dashboard",
      sidebarName: "Inicio",
      navbarName: "Inicio",
      icon: Dashboard,
      component: PatientsContainer
    },
    {
      parent: true,
      sidebarName: "Usuarios",
      navbarName: "Usuarios",
      icon: "users",
      permissions: ["Usuarios"]
    },
    {
      path: "/sandbox/:id?",
      childOf: "demo",
      sidebarName: "Sandbox",
      navbarName: "Sandbox",
      icon: "user-cog",
      permissions: ["Usuarios" /* , "Accesos" */],
      component: Sandbox
    },
    {
      path: "/personas/:types?",
      childOf: "Usuarios",
      icon: "user-tie",
      permissions: ["Usuarios"],
      component: UsersView,
      hidden: true
    },
    {
      path: "/personas/empleado",
      sidebarName: "Empleados",
      navbarName: "Empleados",
      childOf: "Usuarios",
      icon: "user-tie",
      permissions: ["Usuarios"]
    },
    {
      path: "/pacientes",
      sidebarName: "Pacientes",
      navbarName: "Pacientes",
      childOf: "Usuarios",
      icon: "user-tie",
      component: PatientsContainer,
      permissions: ["Usuarios"]
    },
    {
      path: "/expediente/:id?",
      component: MedicalRecords,
      permissions: ["Usuarios"],
      hidden: true
    },
    {
      parent: true,
      sidebarName: "demo",
      navbarName: "demo",
      icon: "user-tie",
      permissions: ["Usuarios"]
    }
  ];
}
