import React from "react";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { ListItem } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.jsx";
import List from "@material-ui/core/List";
import { Image } from "../../components/Images/Image";
import Qr from "components/QR/Qr";
import { AuthFetch } from "../../utils/AuthFetch";
import { LoginManager } from "../../utils/LoginManager";
import { AnchorList } from "../../components/Anchors/AnchorList";
import { IconList } from "../../components/Containers/IconList";
import { SimpleUserForm } from "./SimpleUserForm";
import Hidden from "@material-ui/core/Hidden";
/**
 * @description componente de pruebas
 */
/* eslint eqeqeq: 0*/

export class ProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      type: "",
      hash: "",
      phones: "",
      mails: "",
      photo: ""
    };
  }
  componentDidMount() {
    this.loadUser();
  }
  async loadUser() {
    const loginManager = new LoginManager();
    const id = loginManager.getToken().personID;
    const fetch = new AuthFetch(`/api/persons/${id}`);
    let userData = await fetch.get();
    this.setState(userData.data);
  }
  async reloadQr(id) {
    const fetch = new AuthFetch(`api/persons/${id}`);
    let user = await fetch.put({ hash: true });
    this.setState({
      hash: user.data.hash
    });
  }
  render = () => {
    const { fullname, type, photo, hash, mails, phones, id } = this.state;
    return (
      <React.Fragment>
        <PanelContainer title={fullname} subtitle={type}>
          <GridItem xs={12} sm={6} md={6} xl={3}>
            <Hidden smUp>
              <Qr
                onReload={() => {
                  this.reloadQr(id);
                }}
                data={JSON.stringify({ h: hash })}
                style={{ margin: "100px auto" }}
              />
            </Hidden>
            <Hidden xsDown>
              <Image
                src={photo}
                style={{
                  height: "300px",
                  width: "300px",
                  maxWidth: "100%",
                  margin: "0 auto"
                }}
              />
            </Hidden>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} xl={3}>
            <List>
              <ListItem>
                <IconList icon="user" title="Nombre Completo">
                  {fullname}
                </IconList>
              </ListItem>
              <ListItem>
                <IconList icon="mail-bulk" title="Correos">
                  <AnchorList type="mailto" items={mails} />
                </IconList>
              </ListItem>
              <ListItem>
                <IconList icon="phone" title="Teléfonos">
                  <AnchorList type="tel" items={phones} />
                </IconList>
              </ListItem>
            </List>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} xl={3}>
            <SimpleUserForm id={id} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} xl={3}>
            <Hidden xsDown>
              <Qr
                onReload={() => {
                  this.reloadQr(id);
                }}
                data={JSON.stringify({ h: hash })}
              />
            </Hidden>
          </GridItem>
        </PanelContainer>
      </React.Fragment>
    );
  };
}
