import React, { useRef, useEffect, useState } from "react";
import { DOM } from "../../core/dom";
import { AuthFetch } from "./../../utils/AuthFetch";
import { optionalFn } from "../../core/helpers";
/**
 * @description componente de pruebas
 */
/* eslint eqeqeq: 0*/

export function PrintPatient({ id, record = "ejemplo", afterPrint }) {
  const letter = useRef(null);
  const [patient, setPatient] = useState({
    fullName: "",
    curp: "",
    sex: "",
    birthDate: "",
    rfc: "",
    civilStatus: "",
    spouse: "",
    scholarship: "",
    job: "",
    religion: "",
    referredBy: "",
    procedency: "",
    emergencyNumber: "",
    bloodType: "",
    vih: "",
    alergies: "[]",
    cp: "",
    street: "",
    colony: "",
    state: "",
    city: "",
    phones: "[]",
    mail: ""
  });
  const loadPatient = async () => {
    const fetch = new AuthFetch(`api/patients/${id}`);
    let patient = (await fetch.get()).data;
    await setPatient(patient);
    new DOM();
    letter.current.querySelector(".record").innerHTML = record;
    letter.current.print(optionalFn(afterPrint)());
  };
  useEffect(() => {
    loadPatient();
  }, []);

  let {
    fullName,
    curp,
    sex,
    birthDate,
    rfc,
    civilStatus,
    spouse,
    scholarship,
    job,
    religion,
    referredBy,
    procedency,
    emergencyNumber,
    bloodType,
    vih,
    alergies,
    cp,
    street,
    colony,
    state,
    city,
    phones,
    mail,
    background
  } = patient;
  return (
    <div ref={letter}>
      <center>
        <h5>DRA. ELBA CASILLAS HERNÁNDEZ</h5>
        <p> AV. AVIACION # 4075 INT. 8 COL. PORTA REAL. ZAPOPAN, JAL.</p>
      </center>
      <div style={{ height: "100vh" }}>
        <h1>Expediente clinico</h1>
        <div>
          <h3>Datos Personales</h3>
        </div>
        <hr />
        <div style={styles.md}>
          <strong>Nombre Completo: </strong>
          {fullName}
        </div>
        <div style={styles.md}>
          <strong>Curp: </strong>
          {curp}
        </div>
        <div style={styles.md}>
          <strong>Sexo: </strong>
          {sex == 0 ? "Femenino" : "Masculino"}
        </div>
        <div style={styles.md}>
          <strong>Fecha de nacimiento: </strong>
          {new Date(birthDate).toLocaleString("es-MX", {
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </div>
        <div style={styles.md}>
          <strong>RFC: </strong>
          {rfc}
        </div>
        <div>
          <h3>Familia</h3>
          <hr />
        </div>
        <div style={styles.md}>
          <strong>Estado Civil: </strong>
          {civilStatus}
        </div>
        <div style={styles.md}>
          <strong>Cónyuge: </strong>
          {spouse}
        </div>
        <div>
          <h3>Procedencia</h3>
          <hr />
        </div>
        <div style={styles.md}>
          <strong>Escolaridad: </strong>
          {scholarship}
        </div>
        <div style={styles.md}>
          <strong>Ocupación: </strong>
          {job}
        </div>
        <div style={styles.md}>
          <strong>Religión: </strong>
          {religion}
        </div>
        <div style={styles.md}>
          <strong>Referido por: </strong>
          {referredBy}
        </div>
        <div style={styles.md}>
          <strong>Procedencia: </strong>
          {procedency}
        </div>
        <div>
          <h3>Salud</h3>
          <hr />
        </div>
        <div style={styles.md}>
          <strong>Emergencia: </strong>
          {emergencyNumber}
        </div>
        <div style={styles.md}>
          <strong>Tipo sanguineo: </strong>
          {bloodType}
        </div>
        <div style={styles.md}>
          <strong>¿Padece VIH?: </strong>
          {vih == 0 ? "No" : "Sí"}
        </div>
        <div style={styles.md}>
          <strong>Alergias: </strong>
          {JSON.parse(alergies).join()}
        </div>
        <div style={{ pageBreakAfter: "always" }} />
        <div>
          <h3>Contacto</h3>
          <hr />
        </div>
        <div style={styles.md}>
          <strong>Codigo postal: </strong>
          {cp}
        </div>
        <div style={styles.md}>
          <strong>Calle y numero: </strong>
          {street}
        </div>
        <div style={styles.md}>
          <strong>Colonia: </strong>
          {colony}
        </div>
        <div style={styles.md}>
          <strong>Estado: </strong>
          {state}
        </div>
        <div style={styles.md}>
          <strong>Ciudad: </strong>
          {city}
        </div>
        <div style={styles.md}>
          <strong>Teléfonos: </strong>
          {JSON.parse(phones).join()}
        </div>
        <div style={styles.md}>
          <strong>Correo Electronico: </strong>
          {mail}
        </div>
        <div style={{ pageBreakAfter: "always" }} />
        <div>
          <h3>Antecedentes</h3>
          <hr />
        </div>
        <pre style={styles.md}>{background}</pre>
        <div style={{ pageBreakBefore: "always" }}>
          <h3>Expediente</h3>
          <hr />
        </div>
        <pre className="record" style={styles.md} />
      </div>
    </div>
  );
}
const styles = {
  md: {
    width: "48vw",
    display: "inline-block",
    margin: "1vw"
  }
};
