import React from "react";
import { Image } from "../../components/Images/Image";
import { AuthFetch } from "../../utils/AuthFetch";
import { LoginManager } from "../../utils/LoginManager";
import Tooltip from "@material-ui/core/Tooltip";
import { ProfileView } from "../Users/ProfileView";
import { CustomModal } from "../../components/CustomModal/CustomModal";

/**
 * @description componente de pruebas
 */
/* eslint eqeqeq: 0*/

export class UserButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      photo: "",
      open: false
    };
  }
  componentDidMount() {
    this.loadUser();
  }
  async loadUser() {
    const loginManager = new LoginManager();
    const id = loginManager.getToken().personID;
    const fetch = new AuthFetch(`/api/persons/${id}`);
    let userData = await fetch.get();
    this.setState(userData.data);
  }
  render = () => {
    const { photo, fullname, type, open } = this.state;
    let style = this.props.style;
    return (
      <React.Fragment>
        <Tooltip title={fullname}>
          <div
            style={{
              height: "10rem",
              padding: "10px 0px",
              overflow: "hidden",
              cursor: "pointer",
              display: "block",
              margin: "auto",
              textAlign: "center",
              zIndex: 2,
              ...style
            }}
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            <Image
              src={photo}
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "100%",
                boxShadow: "inset 1px 1px 3px #000077",
                margin: "auto"
              }}
            />
            <div>
              <i>{fullname}</i>
            </div>
            <div>
              <i>{type}</i>
            </div>
          </div>
        </Tooltip>
        <CustomModal
          open={open}
          onClose={() => {
            this.setState({ open: false });
          }}
          styles={{ width: "60vw" }}
        >
          <ProfileView />
        </CustomModal>
      </React.Fragment>
    );
  };
}
