import React, { Component, useState } from "react";
import { AuthFetch } from "../../utils/AuthFetch";
import { FaButton } from "../../components/CustomButtons/FaButton";
import { TextArea } from "../../components/Editor/TextArea";
import GridItem from "components/Grid/GridItem.jsx";
import { optionalFn } from "../../core/helpers";
import swal from "sweetalert";
import { PrintPatient } from "./PrintPatients";
import { ConditionalWall } from "../../components/FilterWall/ConditionalWall";
import { Translator } from "../../utils/Translator";
import Button from "components/CustomButtons/Button.jsx";
import { Toolbar } from "../../components/Panel/ToolBar";
export class MedicalRecords extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      id: props.id || 1,
      records: [],
      openModal: false,
      record: "",
      openRecord: false,
      recordID: "",
      alergies: []
    };
    this.state = this.defaultState;
  }
  componentDidMount() {
    this.loadPatient(this.state.id);
    this.loadRecords();
  }
  async loadPatient(id) {
    const fetch = new AuthFetch(`api/patients/${id}`);
    const patient = (await fetch.get()).data;
    const { fullName, alergies, background, updatedAt } = patient;
    this.setState({
      fullName,
      alergies: alergies ? JSON.parse(alergies) : [],
      background,
      patient,
      updatedAt,
      willPrint: false
    });
  }
  async loadRecords() {
    const id = this.state.id;
    const fetch = new AuthFetch(`api/patients/${id}/records`);
    let records = (await fetch.get()).data;
    this.setState({ records });
  }
  _addRecord() {
    swal("Excelente", "Has creado un nuevo expediente", "success");
    this.loadRecords();
  }
  _update = async (content, patient, recordData, force = false) => {
    let record = recordData.id;
    const fetch = new AuthFetch(`api/patients/${patient}/records/${record}`);
    const response = await fetch.put({
      details: content,
      updatedAt: recordData.updated_at,
      force
    });
    if (response.data.concurrencyError) {
      const answer = await swal({
        text: new Translator(
          "Parece que alguien más ha editado a este expediente. ¿Deseas sobreescribir sus cambios?"
        ).get(),
        icon: "warning",
        buttons: [
          new Translator("No, Thanks").get(),
          new Translator("Yes, Please").get()
        ],
        dangerMode: true
      });
      if (answer) {
        return this._update(content, patient, recordData, true);
      }
      return false;
    }
    this.loadRecords();
    return true;
  };
  printRecords(records, id) {
    if (records.length === 0) {
      return (
        <Toolbar title={`Nuevo Expediente`} defaultOpen={true}>
          <RecordEditor
            value={""}
            onSave={content => {
              const fetch = new AuthFetch(`api/patients/${id}/records/`);
              fetch
                .post({
                  details: content
                })
                .then(() => {
                  this.loadRecords();
                });
              return content;
            }}
          />
        </Toolbar>
      );
    }
    return records.map((item, key) => {
      let date = new Date(item.updated_at).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });
      let buttons = [];
      let open = false;
      if (key === 0) {
        open = true;
        buttons.push(
          <FaButton
            key={`button-${key}`}
            size="12px"
            icon="print"
            title="Imprimir"
            onClick={() => {
              this.setState({ willPrint: true, content: item.details });
            }}
          />
        );
      }
      return (
        <Toolbar
          key={key}
          title={`Actualizado el: ${date}`}
          buttons={buttons}
          defaultOpen={open}
        >
          <RecordEditor
            value={item.details}
            onSave={content => {
              this._update(content, id, item);
              return content;
            }}
            id={id}
          />
        </Toolbar>
      );
    });
  }
  render() {
    const { id, records, background, willPrint, content } = this.state;
    return (
      <>
        <GridItem xs={12}>
          <Toolbar title="Antecedentes">
            <RecordEditor
              value={background}
              onSave={content => {
                const fetch = new AuthFetch(`api/patients/${id}`);
                fetch
                  .put({
                    background: content,
                    updatedAt: this.state.updatedAt.date
                  })
                  .then(() => {
                    this.loadPatient(this.state.id);
                  });
              }}
            />
          </Toolbar>
        </GridItem>
        <GridItem xs={12}>{this.printRecords(records, id)}</GridItem>
        <ConditionalWall conditional={willPrint}>
          <PrintPatient
            id={4}
            record={content}
            afterPrint={() => {
              this.setState({ willPrint: false });
            }}
          />
        </ConditionalWall>
      </>
    );
  }
}
/**
 *
 * @param {onSave,value} param0
 */
function RecordEditor({ onSave, value, id }) {
  const [content, setContent] = useState(value);
  const handleSave = () => {
    let value = optionalFn(onSave)(content);
    swal("Excelente", "modificacion completada", "success");
    setContent(value);
  };
  return (
    <>
      <GridItem xs={12}>
        <TextArea
          value={content}
          onChange={ev => {
            setContent(ev);
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Button color="primary" onClick={handleSave}>
          Guardar
        </Button>
      </GridItem>
    </>
  );
}
