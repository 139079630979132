import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { FontIcon } from "../Icons/FontIcon";
import Tooltip from "@material-ui/core/Tooltip";
/**
 *
 * @param {children,icon,onCLick,size,...rest} param0
 */
export function FaButton({
  children,
  icon,
  onClick,
  size,
  style,
  title,
  ...rest
}) {
  return (
    <Tooltip title={title || icon}>
      <IconButton {...rest} aria-label={icon} size="small" onClick={onClick}>
        <FontIcon iconName={`fa-${icon}`} style={{ fontSize: size, ...style }}>
          <span>{children}</span>
        </FontIcon>
      </IconButton>
    </Tooltip>
  );
}
