import React from "react";
import qrcode from "qrcode-generator-es6";
/**
 *
 * @param {hash}
 */
export function BaseQr({ hash }) {
  const qrSetter = data => {
    const qr = new qrcode(0, "L");
    const div = document.createElement("div");
    qr.addData(data);
    qr.make();
    div.insertAdjacentHTML("beforeEnd", qr.createImgTag(10));
    return div.querySelector("img").src;
  };
  return (
    <img
      alt={hash}
      src={qrSetter(hash)}
      className="qr"
      style={{ width: "100%", height: "auto", margin: "auto" }}
    />
  );
}
