import React, { useState } from "react";
import { ConditionalWall } from "../FilterWall/ConditionalWall";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { FaButton } from "./../CustomButtons/FaButton";

export function Toolbar({ title, buttons, defaultOpen = false, children }) {
  const [open, setFold] = useState(defaultOpen);
  const icon = !open ? "-down" : "-up";
  return (
    <Card>
      <CardHeader>
        <span style={{ fontWeight: "bold", fontSize: "15px" }}>{title}</span>
        <div style={{ float: "right" }}>
          {buttons}
          <FaButton
            icon={`angle${icon}`}
            onClick={() => {
              setFold(!open);
            }}
            title={!open ? "Abrir" : "Cerrar"}
          />
        </div>
      </CardHeader>
      <ConditionalWall conditional={open}>
        <CardBody>{children} </CardBody>
      </ConditionalWall>
    </Card>
  );
}
