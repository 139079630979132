import React from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import "./scss/groupInput.scss";
import { Translator } from "../../utils/Translator";

/* eslint eqeqeq: 0*/
export class SimpleInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ""
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.value == "") {
      return null;
    }
    return prevState;
  }

  onChange = ev => {
    const func = this.props.onChange;

    let value = ev.target.value;
    if (this.props.maxLength != undefined) {
      value = value.slice(0, this.props.maxLength - 1);
    }
    if (typeof func === "function") {
      value = func(value) || value;
    }
    this.setState({ value });
  };

  render() {
    let {
      title,
      name,
      className,
      onBlur,
      type,
      maxLength,
      ...rest
    } = this.props;
    title = new Translator(title).get();
    const { value } = this.state;
    return (
      <CustomInput
        labelText={title}
        value={value}
        onChange={this.onChange}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type,
          className: className,
          onBlur: onBlur,
          name: name,
          maxLength: maxLength,
          ...rest
        }}
      />
    );
  }
}
