import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import { CameraView } from "../../components/UploaderInputs/CameraView";
import { AjaxForm } from "../../components/Containers/AjaxForm";
import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import { GenericSelector } from "../../components/CustomInput/GenericSelector";
import { CustomMultiInput } from "../../components/CustomInput/CustomMultiInput";
import { FormControlLabel, Switch, Button } from "@material-ui/core";
import { optionalFn } from "./../../core/helpers";
import swal from "sweetalert";
import { Translator } from "./../../utils/Translator";
import { AuthFetch } from "./../../utils/AuthFetch";
import GridContainer from "components/Grid/GridContainer";
/* eslint eqeqeq: 0*/
export class PatientsForm extends Component {
  photo = "";
  constructor(props) {
    super(props);
    this.defaultState = {
      phones: "",
      photo: null,
      fullName: "",
      curp: "",
      sex: "",
      birthDate: null,
      rfc: "",
      estadoCivil: "",
      spouse: "",
      scholarship: "",
      job: "",
      religion: "",
      procedency: "",
      referredBy: "",
      emergencyNumber: "",
      bloodType: "",
      vih: false,
      alergies: "",
      cp: "",
      street: "",
      colony: "",
      state: "",
      city: "",
      mail: "",
      disabled: false,
      buttonLabel: "Save"
    };
    this.state = {
      id: props.id || "",
      ...this.defaultState
    };
  }
  componentWillUpdate(nxt) {
    if (nxt.id != this.state.id) {
      this.setState({ id: nxt.id, ...this.defaultState });
    }
  }
  _willSubmit(inputs) {
    const { vih, phones, alergies, updatedAt } = this.state;
    this.setState({ disabled: true, buttonLabel: "Cargando..." });

    if (this.photo) {
      inputs.photo = this.photo;
    }
    if (typeof updatedAt !== "undefined") {
      inputs.updatedAt = updatedAt.date;
    }
    inputs.vih = vih * 1;
    inputs.phones = phones;
    inputs.alergies = alergies;
    return inputs;
  }
  async _afterSubmit(inputs, form, formInputs) {
    if (inputs.data.concurrencyError) {
      const answer = await swal({
        text: new Translator(
          "Parece que alguien más ha editado a este usuario. ¿Deseas sobreescribir sus cambios?"
        ).get(),
        icon: "warning",
        buttons: [
          new Translator("No, Thanks").get(),
          new Translator("Yes, Please").get()
        ],
        dangerMode: true
      });
      if (answer) {
        this._forceUpdate(formInputs);
      }
      return false;
    }

    optionalFn(this.props.onSubmit)(inputs);
  }
  _fillForm = inputs => {
    this.setState(inputs.data);
  };
  _forceUpdate(inputs) {
    const id = this.state.id;
    inputs.force = true;
    const fetch = new AuthFetch(`api/patients/${id}`);
    fetch.put(inputs);
    optionalFn(this.props.onSubmit)(inputs);
  }
  setDate() {
    const birthDate = this.state.birthDate;
    const date = birthDate ? new Date(birthDate) : new Date();
    return date.toISOString().substring(0, 10);
  }
  render() {
    const {
      id,
      photo,
      phones,
      fullName,
      curp,
      sex,
      rfc,
      civilStatus,
      spouse,
      scholarship,
      job,
      religion,
      procedency,
      referredBy,
      emergencyNumber,
      bloodType,
      vih,
      alergies,
      cp,
      street,
      colony,
      state,
      city,
      mail,
      buttonLabel,
      disabled
    } = this.state;
    return (
      <AjaxForm
        path={`/api/patients/${id}`}
        autoload={id !== ""}
        method={id ? "put" : "post"}
        willSubmit={this._willSubmit.bind(this)}
        submitted={this._afterSubmit.bind(this)}
        getter={this._fillForm}
      >
        <div style={{ margin: "2rem" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CameraView
                autoStart={id === ""}
                onPhotoTaken={photo => {
                  this.photo = photo;
                  this.setState({ photo });
                }}
                photo={photo}
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                required
                title="Nombre completo"
                name="fullName"
                value={fullName}
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput title="C.U.R.P" name="curp" value={curp} />
            </GridItem>
            <GridItem xs={12}>
              <GenericSelector
                required
                onChange={ev => {
                  this.setState({ sex: ev.value });
                }}
                title="Sexo"
                name="sex"
                value={sex}
              >
                <option value="0">Femenino</option>
                <option value="1">Masculino</option>
              </GenericSelector>
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                ref="weirdDate"
                required
                title="Fecha de nacimiento"
                name="birthDate"
                type="date"
                value={this.setDate()}
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                title="R.F.C"
                name="rfc"
                type="text"
                maxLength="13"
                value={rfc}
              />
            </GridItem>
            <GridItem xs={12}>
              <GenericSelector
                required
                onChange={ev => {
                  this.setState({ civilStatus: ev.value });
                }}
                title="Estado Civil"
                name="civilStatus"
                value={civilStatus}
              >
                <option value="Soltero">Soltero/a</option>
                <option value="Comprometido">Comprometido/a</option>
                <option value="En Relación">
                  En Relación ( más de 1 Año de noviazgo)
                </option>
                <option value="Casado">Casado/a</option>
                <option value="Unión libre">
                  Unión libre o unión de hecho
                </option>
                <option value="Separado">Separado/a</option>
                <option value="Divorciado">Divorciado/a</option>
                <option value="Viudo">Viudo/a</option>
              </GenericSelector>
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput title="Cónyuge" name="spouse" value={spouse} />
            </GridItem>
            <GridItem xs={12} md={6}>
              <SimpleInput
                title="Escolaridad"
                name="scholarship"
                value={scholarship}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <SimpleInput title="Ocupación" name="job" value={job} />
            </GridItem>
            <GridItem xs={12} md={6}>
              <SimpleInput title="Religión" name="religion" value={religion} />
            </GridItem>
            <GridItem xs={12} md={6}>
              <SimpleInput
                title="Procedencia"
                name="procedency"
                value={procedency}
              />
            </GridItem>
            <GridItem xs={12} md={8}>
              <SimpleInput
                title="Referido Por"
                name="referredBy"
                value={referredBy}
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <SimpleInput
                title="En caso de emergencia llamar a:"
                name="emergencyNumber"
                value={emergencyNumber}
                type="tel"
              />
            </GridItem>
            <GridItem xs={12}>
              <GenericSelector
                required
                onChange={ev => {
                  this.setState({ bloodType: ev.value });
                }}
                title="Grupo Sanguineo"
                name="bloodType"
                value={bloodType}
              >
                <option value="Desconocido">Desconocido</option>
                <option value="O negativo">O negativo.</option>
                <option value="O positivo">O positivo.</option>
                <option value="A negativo">A negativo.</option>
                <option value="A positivo">A positivo.</option>
                <option value="B negativo">B negativo.</option>
                <option value="B positivo">B positivo.</option>
                <option value="AB negativo">AB negativo.</option>
                <option value="AB positivo">AB positivo.</option>
              </GenericSelector>
            </GridItem>
            <GridItem sm={12}>
              <FormControlLabel
                className="customSwitch"
                control={
                  <Switch
                    checked={vih == true}
                    onChange={ev => {
                      this.setState({ vih: ev.target.checked });
                    }}
                    color="primary"
                  />
                }
                label={"Padece VIH"}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomMultiInput
                labelText="Alergias"
                id="telefonos"
                value={alergies}
                onChange={values => {
                  this.setState({
                    alergies: JSON.stringify(values)
                  });
                }}
                inputProps={{
                  name: "alergies",
                  type: "text"
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} md={3}>
              <SimpleInput
                title="Código Postal"
                type="number"
                max="9999"
                name="cp"
                value={cp}
              />
            </GridItem>
            <GridItem xs={12} md={9}>
              <SimpleInput
                title="Calle y Número"
                name="street"
                value={street}
              />
            </GridItem>
            <GridItem xs={12} md={12}>
              <SimpleInput title="Colonia" name="colony" value={colony} />
            </GridItem>

            <GridItem xs={12} md={6}>
              <SimpleInput title="Estado" name="state" value={state} />
            </GridItem>
            <GridItem xs={12} md={6}>
              <SimpleInput title="Ciudad" name="city" value={city} />
            </GridItem>

            <GridItem xs={12}>
              <CustomMultiInput
                labelText="Teléfonos"
                id="telefonos"
                value={phones}
                onChange={values => {
                  this.setState({
                    phones: JSON.stringify(values)
                  });
                }}
                inputProps={{
                  name: "phones",
                  type: "tel"
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                title="Correo Electronico"
                type="mail"
                name="mail"
                value={mail}
              />
            </GridItem>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={"submit"}
              disabled={disabled}
            >
              {new Translator(buttonLabel).get()}
            </Button>
          </GridContainer>
        </div>
      </AjaxForm>
    );
  }
}
