import React from "react";
import { AjaxForm } from "../../components/Containers/AjaxForm";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { Button } from "@material-ui/core";
import { Translator } from "../../utils/Translator";
import { CustomMultiInput } from "../../components/CustomInput/CustomMultiInput";
import swal from "sweetalert";
import { CameraView } from "../../components/UploaderInputs/CameraView";
import { SimpleInput } from "./../../components/CustomInput/SimpleInput";
import { ConditionalWall } from "./../../components/FilterWall/ConditionalWall";
import { GenericSelector } from "../../components/CustomInput/GenericSelector";
import { optionalFn } from "../../core/helpers";
/* eslint eqeqeq: 0*/
export class PersonsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      mails: "",
      phones: "",
      type: props.type || null,
      limitedAccess: new Date().toISOString().substring(0, 10),
      typeSelection: props.type || null,
      photo: null
    };
    this.photo = null;
  }
  _willSubmit(inputs) {
    inputs.phones = this._setToArray(inputs.phones);
    inputs.mails = this._setToArray(inputs.mails);

    inputs.type = inputs.type || this.state.type;
    if (!inputs.type) {
      swal("Oops", new Translator("Type is required").get(), "error");
      return false;
    }
    if (this.photo) {
      inputs.photo = this.photo;
    }
    let customInputs = optionalFn(this.props.willSubmit)(inputs);
    if (typeof customInputs !== "undefined") {
      inputs = customInputs;
    }
    return inputs;
  }
  _setToArray(value) {
    return typeof value === "object" ? value : [value];
  }
  _afterSubmit(inputs, jsx) {
    const submission = this.props.submission;
    if (typeof submission === "function") {
      return submission(inputs.data, jsx);
    }
  }
  componentWillUpdate(ev) {}
  _fillForm = inputs => {
    setTimeout(() => {
      inputs.data.typeSelection = inputs.data.type;
      this.setState(inputs.data);
    }, 300);
  };
  printType() {
    let { type, typeSelection } = this.state;
    return type ? (
      ""
    ) : (
      <GridItem xs={12} sm={12} md={12}>
        <GenericSelector
          key={new Date()}
          title="Tipo"
          name="type"
          id="Type"
          value={typeSelection}
          onChange={data => {
            this.setState({ typeSelection: data.value });
            return data;
          }}
        >
          <option value="Empleado">Empleado</option>
          <option value="Residente">Residente</option>
          <option value="Propietario">Propietario</option>
          <option value="Proveedor">Proveedor</option>
        </GenericSelector>
      </GridItem>
    );
  }
  getDate(limitedAccess) {
    const date = limitedAccess ? new Date(limitedAccess) : new Date();
    return date.toISOString().substring(0, 10);
  }
  render() {
    const {
      fullname,
      mails,
      phones,
      photo,
      limitedAccess,
      typeSelection
    } = this.state;
    const id = this.props.edit || "";
    const method = this.props.edit ? "put" : "post";
    return (
      <AjaxForm
        autoload={id != ""}
        path={`/api/persons/${id}`}
        method={method}
        willSubmit={this._willSubmit.bind(this)}
        submitted={this._afterSubmit.bind(this)}
        getter={this._fillForm}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PanelContainer title="Profile" subtitle="Fill the Form">
              <GridItem xs={12} sm={12} md={12}>
                <CameraView
                  autoStart={id == ""}
                  onPhotoTaken={photo => {
                    this.photo = photo;
                    this.setState({ photo });
                  }}
                  photo={photo}
                />
              </GridItem>
              {this.printType()}
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  onBlur={({ target }) => {
                    this.setState({
                      fullname: target.value
                    });
                  }}
                  labelText="Full Name"
                  id="full-name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "fullname",
                    required: true,
                    value: fullname
                  }}
                  value={fullname}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomMultiInput
                  labelText="Email Address"
                  id="email-address"
                  value={mails}
                  onChange={values => {
                    this.setState({
                      mails: JSON.stringify(values)
                    });
                  }}
                  inputProps={{
                    name: "mails",
                    type: "email"
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomMultiInput
                  key={new Date()}
                  labelText="Phone"
                  id="phone"
                  value={phones}
                  onChange={values => {
                    this.setState({
                      phones: JSON.stringify(values)
                    });
                  }}
                  inputProps={{
                    name: "phones",
                    type: "tel"
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <ConditionalWall
                  conditional={
                    "Proveedor" == typeSelection || "Invitado" == typeSelection
                  }
                >
                  <SimpleInput
                    type="date"
                    name="limitedAccess"
                    title="Fecha limite de acceso"
                    value={this.getDate(limitedAccess)}
                  />
                </ConditionalWall>
              </GridItem>
              {this.props.children}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={"submit"}
              >
                {new Translator("Save").get()}
              </Button>
            </PanelContainer>
          </GridItem>
        </GridContainer>
      </AjaxForm>
    );
  }
}
