import React, { Component } from "react";

import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import { FontIcon } from "../../components/Icons/FontIcon";
import Button from "@material-ui/core/Button";
import { AjaxForm } from "../../components/Containers/AjaxForm";
import swal from "sweetalert";
import { Translator } from "../../utils/Translator";

/* eslint eqeqeq: 0*/

export class SimpleUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailError: false,
      email: "",
      password: "",
      profileID: "",
      id: props.id
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.id != prevState.id) {
      return {
        id: nextProps.id
      };
    }
    return prevState;
  }
  _willSubmit(inputs) {
    const { password, checkPassword } = inputs;
    if (password !== checkPassword) {
      swal("Oops", new Translator("Check Password").get(), "error");
      return false;
    }
    return inputs;
  }
  _setToArray(value) {
    return typeof value === "object" ? value : [value];
  }
  _afterSubmit(inputs, jsx) {
    const submission = this.props.submission;
    swal("Success", new Translator("Sucess").get(), "success");
    if (typeof submission === "function") {
      return submission(inputs, jsx);
    }
  }
  _fillForm = inputs => {
    this.setState(inputs.data);
  };
  render = () => {
    const { id, email } = this.state;
    let autoload = !id == "" || typeof id !== "undefined";
    if (autoload == false) {
      return null;
    }
    return (
      <AjaxForm
        path={`/api/persons/${id}/user`}
        autoload={autoload}
        method="post"
        willSubmit={this._willSubmit.bind(this)}
        submitted={this._afterSubmit.bind(this)}
        getter={this._fillForm}
      >
        <SimpleInput
          title="Email Address"
          type="email"
          name="email"
          value={email}
        />
        <SimpleInput
          title="Password"
          type="password"
          name="password"
          value=""
        />
        <SimpleInput
          title="Validate Password"
          type="password"
          value=""
          name="checkPassword"
        />
        <Button
          type="submit"
          size="small"
          color="primary"
          style={{ float: "right" }}
        >
          Guardar
          <FontIcon iconName="fa-check-circle" />
        </Button>
      </AjaxForm>
    );
  };
}
