import React from "react";
import { Image } from "./../../components/Images/Image";
import { Helpers } from "./../../core/helpers";
import { FaButton } from "./../../components/CustomButtons/FaButton";
import { AnchorList } from "./../../components/Anchors/AnchorList";
import { AsyncDataTable } from "./../../components/Table/AsycnDataTable";
/**
 * @description componente de pruebas
 */
/* eslint eqeqeq: 0*/

export class Sandbox extends React.Component {
  render = () => {
    return (
      <>
        <AsyncDataTable
          titles={{
            photo: "Foto",
            id: "id",
            fullName: "Nombre Completo",
            birthDate: "Edad",
            phones: "Teléfonos",
            bloodType: "Tipo de sangre",
            vih: "VIH"
          }}
          formatContent={item => {
            const helpers = new Helpers();
            return [
              <Image
                src={item.photo}
                style={{
                  width: "100px",
                  height: "70px",
                  borderRadius: "2%",
                  filter: "brightness(1.3)"
                }}
              />,
              item.id,
              item.fullName,
              helpers.dateToYears(item.birthDate),
              <AnchorList type="tel" items={item.phones} />,
              item.bloodType,
              item.vih ? "VIH" : "nulo",
              <>
                <FaButton
                  icon="edit"
                  size="1rem"
                  onClick={() => {
                    this.setState({
                      openPatient: true,
                      openMedicalBackground: false,
                      id: item.id
                    });
                  }}
                />
                <FaButton
                  icon="notes-medical"
                  size="1rem"
                  onClick={() => {
                    this.setState({
                      openPatient: true,
                      id: item.id,
                      view: "record"
                    });
                  }}
                />

                <FaButton
                  icon="trash"
                  style={{ color: "#f00" }}
                  size="1rem"
                  onClick={() => this._delete(item.id)}
                />
              </>
            ];
          }}
          path="api/patients"
        />
      </>
    );
  };
}
