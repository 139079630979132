import React, { Component } from "react";
import { PatientsForm } from "./PatientsForm";
import { AuthFetch } from "./../../utils/AuthFetch";
import { Image } from "../../components/Images/Image";
import { Helpers } from "./../../core/helpers";
import { FaButton } from "./../../components/CustomButtons/FaButton";
import swal from "sweetalert";
import { AnchorList } from "./../../components/Anchors/AnchorList";
import { MedicalRecords } from "./MedicalRecords";
import { AsyncDataTable } from "./../../components/Table/AsycnDataTable";
import { Translator } from "./../../utils/Translator";
export class PatientsContainer extends Component {
  state = {
    openPatient: false,
    id: "",
    name: null,
    openMedicalBackground: false,
    background: "",
    view: "",
    reload: ""
  };
  loadPatients(item) {
    let helpers = new Helpers();
    return [
      <Image
        src={item.photo}
        style={{
          width: "100px",
          height: "70px",
          borderRadius: "2%",
          filter: "brightness(1.3)"
        }}
      />,
      item.id,
      item.fullName,
      helpers.dateToYears(item.birthDate),
      <AnchorList type="tel" items={item.phones} />,
      item.bloodType,
      item.vih ? "VIH" : "nulo",
      <>
        <FaButton
          icon="edit"
          size="1rem"
          onClick={() => {
            this.setState({
              openPatient: true,
              openMedicalBackground: false,
              id: item.id
            });
          }}
        />
        <FaButton
          icon="notes-medical"
          size="1rem"
          onClick={() => {
            this.setState({
              openPatient: true,
              id: item.id,
              view: "record"
            });
          }}
        />
        <FaButton
          icon="trash"
          size="1rem"
          style={{ color: "red" }}
          onClick={() => {
            this._delete(item.id);
          }}
        />
      </>
    ];
  }
  async _delete(id) {
    const fetch = new AuthFetch(`api/patients/${id}`);
    const answer = await swal({
      title: "HEY!!!!",
      text: new Translator(
        "En la version actual: esto no puede deshacerse. ¿Deseas eliminar este paciente?."
      ).get(),
      icon: "warning",
      buttons: [
        new Translator("No, Thanks").get(),
        new Translator("Yes, Please").get()
      ],
      dangerMode: true
    });
    if (answer) {
      const answer2 = await swal({
        text: new Translator("Espero que sepas lo que estas haciendo").get(),
        icon: "warning",
        buttons: [
          new Translator("No lo borres!").get(),
          new Translator("Eliminar paciente").get()
        ],
        dangerMode: true
      });
      if (answer2) {
        await fetch.delete();
      }
    }
    let reload = this.state.reload;
    this.setState({ reload: reload == "" ? " " : "" });
  }
  _handleClose = () => {
    this.setState({
      id: "",
      name: null,
      openMedicalBackground: false,
      background: "",
      view: ""
    });
  };
  _addPatient = inputs => {
    swal("Excelente", "modificacion completada", "success");

    this.setState({
      view: "record",
      openPatient: true,
      id: inputs.data.id
    });
  };
  /**
   * Determina que modal se mostrara en pantalla
   * @param string view
   */
  _switchViews(view) {
    const id = this.state.id;
    switch (view) {
      case "record":
        return <MedicalRecords id={id} onSubmit={this._addPatient} />;

      default:
        return <PatientsForm id={id} onSubmit={this._addPatient} />;
    }
  }
  render() {
    const { openPatient, view, reload } = this.state;

    return (
      <>
        <AsyncDataTable
          reload={reload}
          path="api/patients"
          title="Pacientes."
          subtitle="Datos Generales de distintos pacientes."
          titles={{
            photo: "Foto",
            id: "id",
            fullName: "Nombre Completo",
            birthDate: "Edad",
            phones: "Teléfonos",
            bloodType: "Tipo de sangre",
            vih: "VIH"
          }}
          formatContent={item => {
            return this.loadPatients(item);
          }}
          modalOpen={openPatient}
          onClose={this._handleClose}
          inModal={this._switchViews(view)}
        />
      </>
    );
  }
}
