import React from "react";
import { DataTable } from "../../components/Table/DataTable";
import { AuthFetch } from "../../utils/AuthFetch";
import { CustomSwitchAccess } from "../../components/CustomSwitchAccess/CustomSwitchAccess";
import { AnchorList } from "../../components/Anchors/AnchorList";
import { RegisterContainer } from "../Users/RegisterContainer";
import { CustomModal } from "components/CustomModal/CustomModal";
import QR from "../../components/QR/Qr";
import { Image } from "../../components/Images/Image";
import { FaButton } from "../../components/CustomButtons/FaButton";
/**
 * @description componente de pruebas
 */

export class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.localLoad(props.type) || [],
      id: null,
      type: props.type,
      openQR: false,
      openUser: false
    };
  }
  componentDidMount() {
    this.loadUsers();
  }
  componentWillReceiveProps(nextProps) {
    const content = {};
    if (nextProps.type !== this.state.type) {
      content.type = nextProps.type;
    }
    this.loadUsers(nextProps.type);
    this.setState(content);
  }
  /**
   * @description en base al tipo carga de manera asincrona la tabla de usuarios
   * @param String type
   * @return Promise
   */
  async loadUsers(type = null) {
    const auth = new AuthFetch("api/persons");
    type = type || this.state.type;
    const st = type ? { type } : {};
    const response = await auth.get(st);
    let data = this._formatPersons(response);
    this.setState({ data, id: null, openUser: false });
  }
  /**
   * @description carga desde el cache la informacion de usuarios
   * @param String type
   */
  localLoad(type) {
    const auth = new AuthFetch("api/persons");
    const st = type ? { type } : {};
    const response = auth.local(st);
    let data = this._formatPersons(response);
    return data;
  }
  handleClose = () => {
    this.setState({ openUser: false, openQR: false, id: null });
  };
  addUser = () => {
    this.loadUsers();
  };
  _formatPersons(response) {
    if (response == null) {
      response = { data: [] };
    }
    let data = response.data.map((item, key) => {
      const date = new Date(item.limitedAccess);
      const validity =
        date - 0 !== 0
          ? date.toLocaleString("es-MX", {
              year: "numeric",
              month: "long",
              day: "numeric"
            })
          : "Vitalicia";
      return [
        <Image
          src={item.photo}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "100%",
            boxShadow: "1px 1px 3px #000077"
          }}
        />,
        item.fullname,
        [<AnchorList key={`mails-${key}`} type="mailto" items={item.mails} />],
        [<AnchorList key={`phones-${key}`} type="tel" items={item.phones} />],
        item.type,
        validity,
        [
          <FaButton
            key={item.id + "Fa"}
            size="12px"
            onClick={() => {
              this.setState({ id: item.id, openUser: true });
            }}
            icon="edit"
          />,
          <FaButton
            key={item.id + 1 + "Fa"}
            size="12px"
            onClick={() => {
              this.setState({
                QRdata: {
                  h: item.hash
                },
                openQR: true,
                id: item.id
              });
            }}
            icon="qrcode"
          />,
          <CustomSwitchAccess
            key={`access-${key}`}
            id={item.id}
            hasAccess={item.hasAccess}
            expirationDate={item.limitedAccess}
            onChange={this.addUser}
          />
        ]
      ];
    });
    data.reverse();
    return data;
  }

  async reloadQr(id) {
    const fetch = new AuthFetch(`api/persons/${id}`);
    let user = await fetch.put({ hash: true });
    this.setState({
      QRdata: {
        h: user.data.hash
      }
    });
  }
  render() {
    const { data, id, type, openUser, openQR } = this.state;

    return (
      <main>
        <DataTable
          ref="table"
          title={type || "Usuarios"}
          subtitle={`Control basico de ${type || "Usuarios"}.`}
          titles={[
            "Foto",
            "Nombre",
            "Correo",
            "Telefono",
            "Tipo",
            "Vigencia",
            "Acciones"
          ]}
          content={data}
          modalOpen={openUser}
          onClose={this.handleClose}
          inModal={
            <RegisterContainer id={id} type={type} onSubmit={this.addUser} />
          }
        />
        <CustomModal open={openQR} onClose={this.handleClose}>
          <QR
            onReload={() => {
              this.reloadQr(id);
            }}
            data={JSON.stringify(this.state.QRdata)}
          />
        </CustomModal>
      </main>
    );
  }
}
