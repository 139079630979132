import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import "./scss/TextEditor.scss";
import { optionalFn } from "../../core/helpers";
/*
 * Custom "star" icon for the toolbar using an Octicon
 * https://octicons.github.io
 */
const CustomButton = () => <span className="octicon octicon-star" />;

/*
 * Event handler to be attached using Quill toolbar module
 * http://quilljs.com/docs/modules/toolbar/
 */
function insertStar() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "★");
  this.quill.setSelection(cursorPosition + 1);
}

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
      <option value="1" />
      <option value="2" />
      <option value="" />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <select defaultValue="" className="ql-color">
      <option value="red" />
      <option value="green" />
      <option value="blue" />
      <option value="orange" />
      <option value="violet" />
      <option value="white" />
      <option value="" />
    </select>
    <select defaultValue="" className="ql-background">
      <option value="red" />
      <option value="green" />
      <option value="blue" />
      <option value="orange" />
      <option value="violet" />
      <option value="white" />
      <option value="" />
    </select>
    <button className="ql-insertStar">
      <CustomButton />
    </button>
  </div>
);

/*
 * TextArea component with custom toolbar and content containers
 */
export class TextArea extends React.Component {
  constructor(props) {
    super(props);
    let value = props.value || "";
    value = value.replace(/(?:\r\n|\r|\n)/g, "<br>");
    this.state = { value: value || "" };
  }

  handleChange = html => {
    this.setState({ value: html });
    optionalFn(this.props.onChange)(encodeURIComponent(html));
  };

  render() {
    return (
      <div className="text-editor">
        <CustomToolbar />
        <ReactQuill
          value={this.state.value}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={TextArea.modules}
        />
      </div>
    );
  }
}

/*
 * Quill modules to attach to TextArea
 * See http://quilljs.com/docs/modules/ for complete options
 */
TextArea.modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      insertStar: insertStar
    }
  }
};

/*
 * Quill TextArea formats
 * See http://quilljs.com/docs/formats/
 */
TextArea.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "colorClass"
];
