import React, { Component } from "react";
import { TabsContainer } from "../../components/Tabs/TabsContainer";
import { TabsBlock } from "../../components/Tabs/TabsBlock";
import { PersonsContainer } from "../Forms/PersonsContainer";
import swal from "sweetalert";
import { Translator } from "../../utils/Translator";
import { UsersContainer } from "../Forms/UsersContainer";

/**
 * @description RegisterContaienr es un contenedor para multiples formularios.
 * @props {int} id define el id que se cargara en los inputs para edición
 * @props {function} onSubmit dispara un evento cuando el formulario ha sido llenado.
 */
export class RegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.id };
  }
  /**
   * @description en caso de ser un tipo valido de usuario brinca al
   * siguiente formulario.
   * @param {*} submission
   * @param {*} type
   */
  async next(submission) {
    const id = this.state.id;
    const title = id ? "Edit Password" : "Give Password";
    const answer = await swal({
      text: new Translator(title).get(),
      icon: "success",
      buttons: [
        new Translator("No, Thanks").get(),
        new Translator("Yes, Please").get()
      ],
      dangerMode: false
    });
    if (answer) {
      this.setState({ id: submission.id });
      this.refs.tabulator.goNext();
      return submission;
    }
    this.handlerSubmit(submission);
  }
  handlerSubmit = ev => {
    let onSubmit = this.props.onSubmit;
    if (typeof onSubmit === "function") {
      onSubmit(ev);
    }
  };
  render() {
    const id = this.state.id;
    return (
      <TabsContainer hidden ref="tabulator">
        <TabsBlock active title="Persons">
          <PersonsContainer
            submission={this.next.bind(this)}
            type={this.props.type}
            edit={id}
          />
        </TabsBlock>
        <TabsBlock title="Users">
          <UsersContainer submission={this.handlerSubmit} personID={id} />
        </TabsBlock>
      </TabsContainer>
    );
  }
}
